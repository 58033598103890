<template>
  <div class="position-relative bg-img-hero" :style="{ backgroundImage: `url(${require(`@/assets/svg/components/ben-career-hero.svg`)})`}">
    <div class="container space-top-3 space-bottom-2 position-relative">
      <div class="row justify-content-lg-between align-items-center">
        <div class="col-md-6 col-lg-7">
          <div class="mb-5 mb-md-0">
            <h1 class="mb-4">Effortlessly Manage API Subscriptions</h1>
            <p class="lead mb-4">Unlock Growth with Streamlined Subscription Management</p>
            <a class="btn btn-primary transition-3d-hover" href="https://provider-portal.fabrixapi.com/" target="_blank">Start for Free <i class="far fa-arrow-right ml-1" /></a>
          </div>
        </div>

        <div class="col-md-6 col-lg-5">
          <img class="img-fluid" src="@/assets/svg/illustrations/management/management-feature-3.svg">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Hero'
}
</script>

<style lang="scss" scoped>
</style>
